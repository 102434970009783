.carousel {
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  justify-content: center;
}

.carousel-container {
  overflow: hidden;
  position: absolute;
  width: 100vw;
}

.inner {
  display: flex;
  white-space: nowrap;
  transition: transform 0.5s;
}

.arrow {
  transition: all 0.5s;
  position: absolute;
  background: none;
  border: 0;
  outline: 0;
  border-radius: 50%;
  bottom: 5vh;
  cursor: pointer;
  scale: 75%;
}

.arrow img{
  max-width: 10vh;
  max-height: 10vh;
}

.right {
  right: 5vh;
}

.left {
  left: 5vh;
}

.active iframe {
  display: block;
}

.history {
  display: block;
  opacity: 1;
  z-index: 2;
  position: fixed;
  justify-self: center;
  border-radius: 3px;
  height: 60vh;
  max-width: 66%;
}
