.carousel-item {
  opacity: 1;
  flex-shrink: 0;
  display: flex;
  position: relative;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  height: 90vh;
}

.timeline-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65vh;
  width: 100%;
  margin: 1vh;
}

.event {
  display: flex;
  justify-content: center;
  width: 50vw;
  white-space: normal;
  justify-self: center;
  margin-top: 2vh;
}

.event h3 {
  text-align: center;
}

.timeline-info-container {
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
  height: 25vh;
}

.line-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: -5px;
}

.date-lozenge {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3vh;
  height: 6vh;
  width: 12vw;
  border-radius: 5px;
  background-color: #d52b1e;
  border: transparent solid 1px;
  color: white;
}

.date-lozenge:hover {
  color: #d52b1e;
  border: solid 1px #d52b1e;
  background-color: white;
  cursor: pointer;
}

.active .date-lozenge:hover {
  background-color: #d52b1e;
  outline: transparent solid 1px;
  color: white;
}

.line-container > hr {
  height: 8px;
  background-color: #d52b1e;
  border: 1px solid #d52b1e;
  margin: 0;
  width: 100%;
}

.active .icon-container,
.active .timeline-icon,
.active .timeline-info-container .event,
.active .play-button {
  opacity: 1;
  z-index: 1;
  transition: all 0.5s;
  cursor: pointer;
}


.play-button {
  width: 10vh;
  height: 10vh;
  pointer-events: none;
  position: absolute;
}

.active.carousel-item:hover .play-button {
  scale: 1.5;
}

.icon-container {  
  border-radius: 50%;
  width: 40vh;
  height: 40vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-icon {  
  object-fit: contain;
  opacity: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.hide {
  opacity: 0;
  z-index: -2;
}
